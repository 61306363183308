<template>
  <div>
    <b-card>
      <span class="text-second-sm">Name Page:</span>
      <b-row>
        <b-col cols="6">
          <h1>
            {{
              formatNamePage(
                componentData ? componentData.component.page.name : ""
              )
            }}
          </h1>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <b-button
            :disabled="submitOnLoading"
            variant="primary"
            @click="onSubmit()"
          >
            <span>Submit</span>
            <b-spinner v-if="submitOnLoading" small class="ml-1" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <div class="container">
      <b-card no-body class="mb-0">
        <b-row>
          <b-col cols="12">
            <ckeditor
              v-model="componentData.content"
              :editor="cekEditorConfig.editor"
              :config="cekEditorConfig.editorConfig"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<!-- <template>
  <b-modal id="modal-xl" v-model="props.isShow" ok-only ok-title="Accept" centered size="xl" title="Create Content">
    <ckeditor v-model="cekEditorConfig.editorData" :editor="cekEditorConfig.editor"
      :config="cekEditorConfig.editorConfig" />
  </b-modal>
</template> -->

<script>
import { ref, onUnmounted } from "@vue/composition-api";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/build/ckeditor'
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder'
import { BCard, BRow, BCol, BButton, BSpinner } from "bootstrap-vue";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import CKBox from "@ckeditor/ckeditor5-ckbox/src/ckbox";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CloudServices from "@ckeditor/ckeditor5-cloud-services/src/cloudservices";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Link from "@ckeditor/ckeditor5-link/src/link";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";
import List from "@ckeditor/ckeditor5-list/src/list";
import ListProperties from "@ckeditor/ckeditor5-list/src/listproperties";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Mention from "@ckeditor/ckeditor5-mention/src/mention";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import PictureEditing from "@ckeditor/ckeditor5-image/src/pictureediting";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters";
import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import TextPartLanguage from "@ckeditor/ckeditor5-language/src/textpartlanguage";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
// import LineHeight from "ckeditor5-line-height-plugin/src/lineheight";
import store from "@/store";
import usePageModule from "../usePageModule";
// import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const cekEditorConfig = ref({
      editor: ClassicEditor,
      // editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        height: "500px",
        plugins: [
          Autoformat,
          BlockQuote,
          Bold,
          Heading,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          Table,
          TableToolbar,
          Alignment,
          AutoImage,
          AutoLink,
          CKBox,
          CloudServices,
          Code,
          CodeBlock,
          Essentials,
          FindAndReplace,
          Font,
          Highlight,
          HorizontalLine,
          HtmlEmbed,
          ImageInsert,
          ImageResize,
          ImageUpload,
          IndentBlock,
          LinkImage,
          ListProperties,
          Mention,
          PageBreak,
          PasteFromOffice,
          PictureEditing,
          RemoveFormat,
          SourceEditing,
          SpecialCharacters,
          SpecialCharactersEssentials,
          Strikethrough,
          Subscript,
          Superscript,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TextPartLanguage,
          TextTransformation,
          TodoList,
          Underline,
          WordCount,
          SimpleUploadAdapter,
          // LineHeight,
          //  Base64UploadAdapter
        ],
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "findAndReplace",
            "selectAll",
            "|",
            "heading",
            "|",
            "removeFormat",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "code",
            "subscript",
            "superscript",
            "|",
            "specialCharacters",
            "horizontalLine",
            "pageBreak",
            "|",
            "-",
            "highlight",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "lineHeight",
            "|",
            "link",
            "blockQuote",
            "insertTable",
            "insertImage",
            "mediaEmbed",
            "codeBlock",
            "htmlEmbed",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "|",
            "outdent",
            "indent",
            "alignment",
            "|",
            "textPartLanguage",
            "|",
            "sourceEditing",
          ],
          shouldNotGroupWhenFull: true,
        },
        // exportPdf: {
        //   stylesheets: [
        //     'EDITOR_STYLES',
        //     // Add your custom styles here
        //     // Read more in the documentation:
        //     // https://ckeditor.com/docs/ckeditor5/latest/features/export-pdf.html#plugin-option
        //   ],
        //   fileName: 'export-pdf-demo.pdf',
        //   converterOptions: {
        //     format: 'Tabloid',
        //     margin_top: '20mm',
        //     margin_bottom: '20mm',
        //     margin_right: '24mm',
        //     margin_left: '24mm',
        //     page_orientation: 'portrait'
        //   },
        //   // PROVIDE CORRECT VALUES HERE:
        //   tokenUrl: 'https://example.com/cs-token-endpoint'
        // },
        // exportWord: {
        //   stylesheets: ['EDITOR_STYLES'],
        //   fileName: 'export-word-demo.docx',
        //   converterOptions: {
        //     format: 'B4',
        //     margin_top: '20mm',
        //     margin_bottom: '20mm',
        //     margin_right: '12mm',
        //     margin_left: '12mm',
        //     page_orientation: 'portrait'
        //   },
        //   // PROVIDE CORRECT VALUES HERE:
        //   tokenUrl: 'https://example.com/cs-token-endpoint'
        // },
        fontFamily: {
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22, 44, 66, 88],
          supportAllValues: true,
        },
        lineHeight: {
          // specify your otions in the lineHeight config object. Default values are [ 0, 0.5, 1, 1.5, 2 ]
          options: [0.5, 1, 1.5, 2, 2.5],
        },
        htmlEmbed: {
          showPreviews: true,
        },
        image: {
          styles: ["alignCenter", "alignLeft", "alignRight"],
          resizeOptions: [
            {
              name: "resizeImage:original",
              label: "Original",
              value: null,
            },
            {
              name: "resizeImage:50",
              label: "50%",
              value: "50",
            },
            {
              name: "resizeImage:75",
              label: "75%",
              value: "75",
            },
          ],
          toolbar: [
            "imageTextAlternative",
            "toggleImageCaption",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "imageStyle:side",
            "|",
            "resizeImage",
          ],
          insert: {
            integrations: ["insertImageViaUrl"],
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: "https://",
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        mention: {
          feeds: [
            {
              marker: "@",
              feed: [
                "@apple",
                "@bears",
                "@brownie",
                "@cake",
                "@cake",
                "@candy",
                "@canes",
                "@chocolate",
                "@cookie",
                "@cotton",
                "@cream",
                "@cupcake",
                "@danish",
                "@donut",
                "@dragée",
                "@fruitcake",
                "@gingerbread",
                "@gummi",
                "@ice",
                "@jelly-o",
                "@liquorice",
                "@macaroon",
                "@marzipan",
                "@oat",
                "@pie",
                "@plum",
                "@pudding",
                "@sesame",
                "@snaps",
                "@soufflé",
                "@sugar",
                "@sweet",
                "@topping",
                "@wafer",
              ],
              minimumCharacters: 1,
            },
          ],
        },
        placeholder: "Type or paste your content here!",
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
            "toggleTableCaption",
          ],
        },
        wproofreader: {
          // PROVIDE CORRECT VALUE HERE:
          serviceId: "service ID",
          lang: "auto",
          srcUrl:
            "https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js",
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: "https://ingco.co.id/Image/blog",

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            // 'X-CSRF-TOKEN': 'CSRF-Token',
            // 'Content-Accept': 'application/json',
            // 'Authorization': 'Bearer <JSON Web Token>',
            // 'Access-Control-Allow-Headers': '*',
            // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Max-Age': 0
          },
        },
      },
    });

    const PAGE_STORE_MODULE_NAME = "cms-pages";

    // Register module
    if (!store.hasModule(PAGE_STORE_MODULE_NAME)) {
      store.registerModule(PAGE_STORE_MODULE_NAME, usePageModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAGE_STORE_MODULE_NAME)) {
        store.unregisterModule(PAGE_STORE_MODULE_NAME);
      }
    });

    const componentData = ref({
      content: null,
    });
    const editorData = ref("");
    const { id, component_type } = context.root.$route.params;
    console.log(context.root.$route.params);

    const formatNamePage = (text) => {
      let result = text.replace(/[-|_]/g, " ");
      result = result
        .split(" ")
        .map((element) => {
          if (element !== "") {
            return element[0].toString().toUpperCase() + element.slice(1);
          }
          return element;
        })
        .join(" ");
      return result;
    };

    store
      .dispatch("cms-pages/fetchComponentById", {
        id,
        componentType: component_type,
      })
      .then((response) => {
        if (response.status === 200) {
          componentData.value = response.data;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const submitOnLoading = ref(false);

    const onSubmit = () => {
      submitOnLoading.value = true;
      store
        .dispatch("cms-pages/updateComponentContent", {
          id,
          componentType: component_type,
          data: {
            content: componentData.value.content,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          submitOnLoading.value = false;
        });
    };
    return {
      cekEditorConfig,
      props,
      editorData,
      componentData,
      formatNamePage,
      onSubmit,
      submitOnLoading,
    };
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 500px;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
</style>
